/**
 * This file just exports icons that we have adopted for reuse.
 */
import {
  AddCircleOutline,
  ArrowCircleDown,
  ArrowCircleRightOutlined,
  ArrowDropDown,
  ArrowUpwardRounded,
  AssignmentOutlined,
  AssignmentTurnedInOutlined,
  BugReport,
  BuildOutlined,
  Cancel,
  CheckCircle,
  ChevronRight,
  Close,
  ColorLensOutlined,
  Commit,
  DoDisturbOn,
  DownloadingOutlined,
  FileCopyOutlined,
  FitScreen,
  Fullscreen,
  FullscreenExit,
  InfoOutlined,
  LockOpenOutlined,
  LockOutlined,
  Error as MdError,
  Menu,
  MoreHoriz,
  MyLocation,
  Pending,
  PlayCircleOutline,
  PlaylistAdd,
  Report,
  RotateLeft,
  RotateRight,
  Security,
  SubdirectoryArrowRight,
  SyncRounded,
  Visibility,
  VisibilityOff,
  ZoomIn,
  ZoomOut
} from '@mui/icons-material';

import {
  FaApple,
  FaBitbucket,
  FaCodeBranch,
  FaGitlab,
  FaLinux,
  FaWindows
} from 'react-icons/fa6';

import {
  VscAzureDevops,
  VscCloudDownload,
  VscCloudUpload,
  VscCollapseAll,
  VscDiffSingle,
  VscExpandAll,
  VscGithubInverted,
  VscGitMerge,
  VscGitPullRequest,
  VscGitPullRequestClosed,
  VscIssues,
  VscKey,
  VscLayoutSidebarLeft,
  VscLayoutSidebarRight,
  VscRepoForked
} from 'react-icons/vsc';

import {
  ModAccessTokenIcon,
  ModAddColumnLeftIcon,
  ModAddColumnRightIcon,
  ModAgentsIcon,
  ModAuditLogIcon,
  ModBasicRecipeIcon,
  ModBuilderIcon,
  ModCheckedOutlineIcon,
  ModCollapseAllIcon,
  ModCollapseIcon,
  ModColumnsIcon,
  ModCommandPalletIcon,
  ModComposeRecipeIcon,
  ModCreateNewIcon,
  ModCsvIcon,
  ModDatatablesIcon,
  ModDeleteIcon,
  ModDeployRecipesIcon,
  ModDevCenterIcon,
  ModDiscordIcon,
  ModEditIcon,
  ModEmailIcon,
  ModErrorIcon,
  ModExcelIcon,
  ModExpandAllIcon,
  ModExpandIcon,
  ModFileAddedIcon,
  ModFileModifiedIcon,
  ModFileRemovedIcon,
  ModFileRenamedIcon,
  ModFilterListIcon,
  ModGraphQLIcon,
  ModHelpIcon,
  ModHistoryIcon,
  ModInfoIcon,
  ModJsonIcon,
  ModLeftClickRotateIcon,
  ModMarketplaceIcon,
  ModOrganizationsIcon,
  ModQuarantinedIcon,
  ModRainyIcon,
  ModRecommendedSortIcon,
  ModReloadIcon,
  ModRepositoryIcon,
  ModRightClickPanIcon,
  ModSaveFloppyIcon,
  ModSaveToBrowser,
  ModScrollWheelIcon,
  ModSearchIcon,
  ModSettingsIcon,
  ModShareIcon,
  ModSlackIcon,
  ModSortAZAlphaIcon,
  ModSortZAAlphaIcon,
  ModSunnyIcon,
  ModSunnySpellsIcon,
  ModTagIcon,
  ModUndoIcon,
  ModUploadIcon,
  ModWarningIcon,
  ModWorkerIcon
} from './moderne-icons';

import { SpeedDialIcon } from '@mui/material';

import { IoSparklesSharp } from 'react-icons/io5';

// Left Navigation

export const BrowseRecipesIcon = ModMarketplaceIcon;
export const DeployRecipesIcon = ModDeployRecipesIcon;
export const HistoryIcon = ModHistoryIcon;
export const RepositoryIcon = ModRepositoryIcon;
export const AuditLogsIcon = ModAuditLogIcon;
export const WorkerIcon = ModWorkerIcon;
export const QuarantinedIcon = ModQuarantinedIcon;
export const AgentsIcon = ModAgentsIcon;
export const ReportsIcon = ModDatatablesIcon;
export const AccessTokenIcon = ModAccessTokenIcon;
export const MenuIcon = Menu;
export const AdminIcon = ModSettingsIcon;
export const DevCenterIcon = ModDevCenterIcon;

// Org & repo
export const OrganizationIcon = ModOrganizationsIcon;
// Common
export const BranchIcon = FaCodeBranch;
export const CheckIcon = ModCheckedOutlineIcon;
export const LoadingAstIcon = DownloadingOutlined;
export const QueuedAstIcon = Pending;
export const ChevronRightIcon = ChevronRight;
export const CloseIcon = Close;
export const CollapseAllIcon = VscCollapseAll;
export const CollapseAllIcon2 = ModCollapseAllIcon;
export const CollapseIcon = ModCollapseIcon;
export const ConfigureIcon = BuildOutlined;
export const CopyConfirmedIcon = AssignmentTurnedInOutlined;
export const CopyIcon = AssignmentOutlined;
export const DebugIcon = BugReport;
export const DeleteIcon = ModDeleteIcon;
export const DiffIcon = VscDiffSingle;
export const DownloadIcon = VscCloudDownload;
export const UploadIcon = VscCloudUpload;
export const DropDownArrowIcon = ArrowDropDown;
export const DuplicateIcon = FileCopyOutlined;
export const ExpandAllIcon = VscExpandAll;
export const ExpandIcon = ModExpandIcon;
export const ExpandAllIcon2 = ModExpandAllIcon;
export const GitCommitIcon = Commit;
export const GraphQLIcon = ModGraphQLIcon;
export const HelpIcon = ModHelpIcon;
export const InfoIcon = ModInfoIcon;
export const WarningIcon = ModWarningIcon;
export const ErrorIcon = ModErrorIcon;
export const OpenIssueIcon = VscIssues;
export const PullRequestIcon = VscGitPullRequest;
export const ForkIcon = VscRepoForked;
export const SearchIcon = ModSearchIcon;
export const SettingsIcon = ModSettingsIcon;
export const CancelIcon = Cancel;
export const GpgKeyIcon = VscKey;
export const ArrowCircleDownIcon = ArrowCircleDown;
export const RightArrowIcon = ArrowCircleRightOutlined;
export const QuarantinedRepoIcon = LockOutlined;
export const UnquarantinedRepoIcon = LockOpenOutlined;
export const OriginInfoIcon = InfoOutlined;
export const UpArrowIcon = ArrowUpwardRounded;
export const FilterListIcon = ModFilterListIcon;
export const ColumnsIcon = ModColumnsIcon;
export const AiAssistIcon = IoSparklesSharp;
export const VisibilityIcon = Visibility;
export const VisibilityOffIcon = VisibilityOff;

// User Configurations
export const BitBucketIcon = FaBitbucket;
export const GitLabIcon = FaGitlab;
export const GitHubIcon = VscGithubInverted;
export const AzureDevOpsIcons = VscAzureDevops;

// Sorting
export const RecommendedSortIcon = ModRecommendedSortIcon;
export const SortAlphabeticalIcon = ModSortAZAlphaIcon;
export const SortReverseAlphabeticalIcon = ModSortZAAlphaIcon;

// Recipe
export const AddToListIcon = PlaylistAdd;
export const BasicRecipeIcon = ModBasicRecipeIcon;
export const ComposedRecipeIcon = ModComposeRecipeIcon;
export const ReplayIcon = ModReloadIcon;
export const RunIcon = PlayCircleOutline;
export const ShareIcon = ModShareIcon;

// Builder
export const ImportFromFileIcon = ModUploadIcon;
export const NewRecipeIcon = ModCreateNewIcon;
export const GoToOriginIcon = MyLocation;
export const TagIcon = ModTagIcon;
export const ColorDebuggerIcon = ColorLensOutlined;
export const ExplorerIcon = ModBuilderIcon;
export const EditIcon = ModEditIcon;
export const SaveIcon = ModSaveFloppyIcon;
export const LoadCustomRecipeIcon = ModBuilderIcon;
export const AddNodeIcon = SpeedDialIcon;
export const AddNodeIcon2 = AddCircleOutline;
export const SavedRecipeIcon = ModSaveToBrowser;
export const SavingRecipeIcon = SyncRounded;
export const MoveTopologyToCenterIcon = VscLayoutSidebarLeft;
export const MoveTopologyToSideBarIcn = VscLayoutSidebarRight;
export const ScrollWheelIcon = ModScrollWheelIcon;
export const RightClickPanIcon = ModRightClickPanIcon;
export const LeftClickRotateIcon = ModLeftClickRotateIcon;
export const UndoIcon = ModUndoIcon;

// Runs
export const CollapseDialogIcon = FullscreenExit;
export const ExpandDialogIcon = Fullscreen;
export const MoreActionsIcon = MoreHoriz;

// Results
export const RecipeInheritedFromIcon = SubdirectoryArrowRight;
export const FileModifiedIcon = ModFileModifiedIcon;
export const FileAddedIcon = ModFileAddedIcon;
export const FileRemovedIcon = ModFileRemovedIcon;
export const FileRenamedIcon = ModFileRenamedIcon;
export const UnavailableIcon = DoDisturbOn;

// Navigation Menus
export const CommandPaletteIcon = ModCommandPalletIcon;

// Commit Jobs
export const RainyIcon = ModRainyIcon;
export const SunnyIcon = ModSunnyIcon;
export const SunnySpellsIcon = ModSunnySpellsIcon;
export const MergeIcon = VscGitMerge;
export const ClosePullRequestIcon = VscGitPullRequestClosed;

// Server Status
export const StatusUpIcon = CheckCircle;
export const StatusWarnIcon = Report;
export const StatusDownIcon = MdError;

// Data tables
export const DataTablesIcon = ModDatatablesIcon;
export const ExcelIcon = ModExcelIcon;
export const CsvIcon = ModCsvIcon;
export const JsonIcon = ModJsonIcon;

// Support
export const DiscordIcon = ModDiscordIcon;
export const SlackIcon = ModSlackIcon;
export const EmailIcon = ModEmailIcon;

// Moderne CLI
export const WindowsIcon = FaWindows;
export const MacIcon = FaApple;
export const LinuxIcon = FaLinux;

// Update components
export const RestrictedIcon = Security;

// Visualization components
export const ZoomInIcon = ZoomIn;
export const ZoomOutIcon = ZoomOut;
export const RecenterIcon = FitScreen;
export const ReverseTreeIcon = RotateLeft;
export const ResetTreeIcon = RotateRight;

export const AddColumnLeftIcon = ModAddColumnLeftIcon;
export const AddColumnRightIcon = ModAddColumnRightIcon;
