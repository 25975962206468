import { PureFunction } from '@bonsai-components/utility-types';
import { useSession } from 'next-auth/react';

import {
  RepositoryFragment,
  useHasScmTokenLazyQuery
} from '../__generated__/apollo-hooks';
import { downloadBlob } from '../helpers/download.helper';
import { useScmAuthorization } from './use-authorization.hooks';

type AuthorizedDownloadOptions = AuthenticatedDownloadOptions & {
  Repository?: RepositoryFragment;
};

/**
 * Provides a standardized way to download a file from the platform
 * while ensuring the user is not only authenticated but also
 * optionally authorized to access the file based on a repository identity
 */
export const useAuthDownload: PureFunction<
  void,
  PureFunction<AuthorizedDownloadOptions, Promise<boolean>>
> = () => {
  const [hasToken] = useHasScmTokenLazyQuery();
  const { handleAuthorization } = useScmAuthorization();
  const handleAuthenticatedDownload = useAuthenticatedDownload();

  const authDownload = async ({ Repository, path, fileName, errorMessage }) => {
    // eslint-disable-next-line no-console
    console.info(
      `Attempting to download:\n\tpath: ${path}\n\tfileName: ${fileName}`
    );

    if (Repository) {
      const { data } = await hasToken({
        variables: {
          resourceId: Repository.origin
        }
      });

      if (data?.scm.isAuthorized === false) {
        handleAuthorization(data.scm.resourceId);
        return false;
      }
    }
    return await handleAuthenticatedDownload({
      path,
      fileName,
      errorMessage
    });
  };
  return authDownload;
};

export type AuthenticatedDownloadOptions = {
  path: string;
  fileName: string;
  errorMessage: string;
};

const useAuthenticatedDownload: PureFunction<
  void,
  PureFunction<AuthenticatedDownloadOptions, Promise<boolean>>
> = () => {
  const { data } = useSession();

  /**
   *
   * @param path source of the file to download
   * @param fileName filename to save the file as
   * @param handleError Error handler
   * @param destination Destination to save the file to: `system` or `local`
   */
  const handleAuthenticatedDownload = async ({
    path,
    fileName,
    errorMessage
  }) => {
    const url = path;
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: 'Bearer ' + data.accessToken
      })
    });

    if (!response.ok) {
      throw Error(errorMessage);
    } else if (response.status === 204) {
      // FIXME: too patch-specific
      throw Error('No patch to download. Recipe made no changes');
    } else {
      const blob = await response.blob();
      downloadBlob(blob, fileName);
      return true;
    }
  };

  return handleAuthenticatedDownload;
};
