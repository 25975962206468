import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import {
  GridToolbarContainer,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';

export const GridToolbarWithQuickFilter: FunctionComponentWithChildren = ({
  children
}) => (
  <GridToolbarContainer>
    <GridToolbarQuickFilter />
    {children}
  </GridToolbarContainer>
);
